<template>
  <div class="sys-tree-container">
    <el-row v-if="Object.keys($slots).length" class="row _1">
      <el-col :span="24">
        <div
          class="group-list"
          style="display: flex; flex-wrap: wrap; gap: 10px"
        >
          <slot name="btn-create" />
          <slot name="btn-update" />
          <slot name="btn-delete" />
        </div>
      </el-col>
    </el-row>
    <el-row class="row _2">
      <el-col :span="24">
        <el-input
          v-model.trim="filterKeywords"
          style="margin: 10px auto"
          clearable
          placeholder="筛选过滤"
        />
      </el-col>
    </el-row>
    <div class="tree-wrapper">
      <el-tree
        ref="tree"
        v-bind="$attrs"
        :key="key"
        class="tree"
        :expand-on-click-node="false"
        :default-expand-all="false"
        :node-key="nodeKey"
        :default-expanded-keys="currentExpandedKeys"
        :data="tree"
        :props="props"
        :filter-node-method="$_tree_filterTreeNode"
        :highlight-current="true"
        @check="onNodeCheck"
        @node-click="onNodeClick"
        @current-change="onNodeChange"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import { isArray, isPlainObject } from '@/utils/validate'
import treeFilter from './mixins/filter'

const defaultTreeNode = () => ({
  id: null,
  value: null,
  label: '',
  parent: {
    id: null,
    value: null,
    label: ''
  }
  // children: []
})

export default {
  name: 'SysTree',
  mixins: [treeFilter],
  props: {
    name: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    props: {
      type: Object,
      default: () => ({
        children: 'children',
        label: 'label'
      })
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    expandedLevel: {
      type: Number,
      default: 10
    },
    value: {
      type: Object,
      default: defaultTreeNode
    }
  },
  data() {
    return {
      filterKeywords: '',
      defaultTreeNode: null,
      resizeHandler: null,
      layout: {
        buttonH: 0, // 树操作按钮区高度
        buttonDH: 29, // 树操作按钮区默认高度（有按钮且按钮行没有换行的情况下），默认 29px
        buttonMB: 10, //  树操作按钮区下外边距，默认 10px
        filterH: 58 // 树节点筛选框高度
      },
      currentNodeKey: null
    }
  },
  computed: {
    tree() {
      // 缓存
      return this.data
    },
    currentExpandedKeys() {
      return this.$attrs['expanded-level']
        ? this.$attrs['expanded-level']
        : this.recursiveTreeNodes(this.tree, [], 1)
    },
    key() {
      return `${this.name}${Math.random()}`
    },
    /**
     * @returns {string}
     */
    buttonMinHeight() {
      return `${this.layout.buttonDH}px`
    },
    /**
     * @returns {string}
     */
    buttonHeight() {
      return `${this.layout.buttonH}px`
    },
    /**
     * @returns {string}
     */
    buttonMarginBottom() {
      return `${this.layout.buttonMB}px`
    },
    /**
     * @return {string}
     */
    filterHeight() {
      return `${this.layout.filterH}px`
    }
  },
  watch: {
    /**
     * @param {string} keywords
     * @returns {void}
     * @description 过滤树节点
     */
    filterKeywords(keywords) {
      this.$refs.tree.filter(keywords)
    }
  },
  created() {
    console.log('tree', Object.keys(this.$slots).length)
    this.defaultTreeNode = JSON.parse(JSON.stringify(this.value))
  },
  activated() {
    this.handleResize()
  },
  async mounted() {
    this.resizeHandler = debounce(() => {
      this.handleResize()
    }, 160)
    window.addEventListener('resize', this.resizeHandler)

    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
    this.resizeHandler = null
  },
  methods: {
    async handleResize() {
      await this.$halt(16)

      try {
        let bdh, bh, bmb, fh
        const $treeCon = this.$el
        if ($treeCon) {
          const $treeBtns = $treeCon.querySelector('.row._1')
          bh = $treeBtns.getBoundingClientRect()?.height ?? 0
          bdh = bh ? 29 : 0
          bmb = bh ? 10 : 0

          const $treeFilters = $treeCon.querySelector('.row._2')
          fh = $treeFilters.getBoundingClientRect()?.height ?? 58

          const layout = {
            buttonDH: bdh,
            buttonH: bh,
            buttonMB: bmb,
            filterH: fh
          }

          this.layout = layout
        }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * @param {Array} parentNode
     * @param {Array} iterator
     * @param {number} depth
     * @returns {Array}
     * @description 遍历树节点，目前用于默认展开指定“expandedLevel”层级
     */
    recursiveTreeNodes(parentNode, iterator, depth) {
      if (depth >= this.expandedLevel) return iterator
      isArray(parentNode) &&
        parentNode.forEach((childNode) => {
          iterator.push(childNode[this.nodeKey])

          this.recursiveTreeNodes(childNode.children, iterator, depth + 1)
        })
      return iterator
    },
    /**
     * 快速过滤树节点
     */
    filterTreeNode(value, data) {
      try {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      } catch (error) {
        console.error(error)
      }
      return true
    },
    /**
     * 树节点复选框选中事件
     */
    onNodeCheck(node, { checkedKeys, halfCheckedKeys }) {
      this.$emit('check', [...checkedKeys, ...halfCheckedKeys])
    },
    /**
     * 树节点改变事件
     */
    onNodeChange(node, $node) {
      this.$emit('node-change', node, $node)

      let currentDirNode = JSON.parse(JSON.stringify(this.defaultTreeNode))

      try {
        const current = {
          value: node[this.nodeKey],
          ...node
        }
        let parent = {}
        const parentNode = $node.parent.data
        if (isPlainObject(parentNode)) {
          parent = {
            value: parentNode[this.nodeKey],
            ...parentNode
          }
        } else {
          console.log('sys-tree: available root node')
        }

        // let children = null
        // const childNodes = $node.childNodes
        // if (isArray(childNodes) && childNodes.length) {
        //   children = childNodes
        //     .map((node) => {
        //       const childNode = node.data
        //       if (isPlainObject(childNode)) {
        //         return {
        //           ...node.data,
        //           value: node.data[this.nodeKey]
        //         }
        //       } else {
        //         return null
        //       }
        //     })
        //     .filter((v) => !!v)
        // } else {
        //   children = []
        // }

        currentDirNode = {
          ...current,
          parent
          // children
        }

        console.groupCollapsed(' 【sysTree】node change')
        console.log(' current node:', currentDirNode)
        console.groupEnd()
      } catch (error) {
        console.error(error)
      }
      this.$emit('input', currentDirNode)
    },
    /**
     * 树节点点击事件
     */
    async onNodeClick(node, $node) {
      await this.$halt()
      this.$emit('node-click', node, $node)

      // if (this.currentNodeKey === node) {
      //   console.log('相等')
      // }
      // this.currentNodeKey = node
    },
    /**
     * @param {array} keys
     * @param {boolean=} [leafOnly=false] - 若为 true 则仅设置叶子节点的选中状态，默认值为 false
     */
    setCheckedKeys(keys, leafOnly = false) {
      this.$refs.tree.setCheckedKeys(isArray(keys) ? keys : [], leafOnly)
    },
    /**
     * @description 不包含父半节点
     */
    getCheckedKeys() {
      const checkedKeys = this.$refs.tree.getCheckedKeys()
      return checkedKeys
    },
    /**
     * @description 全部节点
     */
    getAllCheckedKeys() {
      const checkedKeys = this.$refs.tree.getCheckedKeys()
      const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
      return [...checkedKeys, ...halfCheckedKeys]
    }
    /**
     * 添加树节点
     */
    // appendNode(data) {
    //   const newChild = { id: Math.random(), label: 'testtest', children: [] }
    //   if (!data.children) {
    //     this.$set(data, 'children', [])
    //   }
    //   data.children.push(newChild)
    // },
    /**
     * 删除树节点
     */
    // removeNode(node, data) {
    //   const parent = node.parent
    //   const children = parent.data.children || parent.data
    //   const index = children.findIndex((d) => d.id === data.id)
    //   children.splice(index, 1)
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';

$buttonHeight: v-bind(buttonHeight); /* 树操作按钮高度  */
$buttonMarginBottom: v-bind(buttonMarginBottom); /* 树操作按钮下外边距高度  */
$filterHeight: v-bind(filterHeight); /*  树节点筛选框高度 */
.sys-tree-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.row._1 {
  min-height: v-bind(buttonMinHeight);
  margin-bottom: v-bind(buttonMarginBottom);
}

.row._2 {
  height: 58px;
}

.tree-wrapper {
  @include scrollbar;

  width: 100%;
  /*height: calc(100% - 29px - 10px -  58px); /
  /* 树节点区域高度 = 树容器总高度 - 树操作按钮高度 - 树操作按钮下外边距高度 - 树节点筛选框高度 */
  height: calc(
    100% - #{$buttonHeight} - #{$buttonMarginBottom} - #{$filterHeight}
  );
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 20px;

  :deep(.el-tree) {
    width: 100%;
    display: inline-block !important;
  }

  :deep(.el-tree-node > .el-tree-node__children) {
    overflow: visible;
  }
}

:deep(.group-list) {
  .el-button + .el-button {
    margin-left: 0px;
  }

  .el-button--mini {
    padding: 8px 5px;
  }
}
</style>
